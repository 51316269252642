
import React from "react"
import { navigate } from "gatsby"
import styled from "styled-components"
import Button from "../components/button"


function encode(data) {
    return Object.keys(data).map((key) =>
        encodeURIComponent(key) + '=' + encodeURIComponent(data[key])).join('&')
}

const ContactForm = () => {
    const [state, setState] = React.useState({})

    const handleChange = (e) => {
        setState({ ...state, [e.target.name]: e.target.value })
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        const form = e.target
        fetch('/', {
            method: 'POST',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            body: encode({
                'form-name': form.getAttribute('name'),
                ...state,
            }),
        })
            .then(() => navigate(form.getAttribute('action')))
            .catch((error) => alert(error))
    }

    return (
        <div>
            <Box
                name='contact'
                method='POST'
                data-netlify='true'
                data-netlify-honeypot='bot-field'
                action='/success'
                onSubmit={handleSubmit}
            >
                <input type="hidden" name="form-name" value="contact" />
                <p hidden>
                    Don't fill this out: <input name="bot-field" onChange={handleChange} />
                </p>

                <div>
                    <div>
                        <Label htmlFor='name'>
                            Name
    </Label>
                        <Input type='text' name="name" id="name" mb={3} required onChange={handleChange} />
                    </div>
                    <div>
                        <Label htmlFor='email'>
                            Email
    </Label>
                        <Input type="email" name="email" id="email" mb={3} required onChange={handleChange} />
                    </div>
                </div>
                <Label htmlFor='subject'>
                    Subject
    </Label>
                <Input type="text" name="subject" id="subject" mb={3} required onChange={handleChange} />

                <Label htmlFor='message'>
                    Message
    </Label>
                <Textarea name="message" id="message" rows="5" mb={3} required onChange={handleChange} />

                <Button type='submit'>Send</Button>
            </Box>
        </div>

    )
}

export default ContactForm

const Box = styled.form`
    color: ${props => props.theme.colors.background},
`
const Label = styled.label`

`
const Input = styled.input`
width: 100%;
`

const Textarea = styled.textarea`
width: 100%;
`
import React from "react"
import { graphql } from "gatsby"
import ContactForm from "../components/contact-form"
import SEO from "../components/seo"

import Hero from "../components/hero"

const Contact = ({data}) => {
    const title = data.mdx.frontmatter.heading
    const hero = data.mdx.frontmatter.hero.childImageSharp.fluid.src
    return (
        <div>
            <SEO
                title={title} />
            <Hero
            imageSrc={hero}
            >
                <h1 style={{ textAlign: "center" }}>
                    {title}
            </h1>
            </Hero>
            <ContactForm />
        </div>
    )
}

export default Contact

export const query = graphql`
query ContactQuery {
    mdx(fileAbsolutePath: {regex: "/contact.md/"}) {
        frontmatter {
            heading
            hero {
                childImageSharp {
                    fluid(maxWidth: 2500) {
                        src
                    }
                }
            }
        }
    }
}
`